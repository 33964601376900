var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Modal from 'components/Modal.vue';
import Tooltip from 'components/Tooltip.vue';
import { getVideosCRM } from '@/shared/utils/Infosets.utils';
import { getApiExportBaseUrl, showNotify } from '@/shared/utils/helpers';
let SalesforceConfigCard = class SalesforceConfigCard extends Vue {
    constructor() {
        super(...arguments);
        this.salesforceClientId = '3MVG9kBt168mda_8emrcrHB1P70NkwEru9oZsJRamCrxR2T79Tqu0xLl7cBPzm3un.GE6ZA_T8.18hZ9O32tt';
        this.urlSF = `https://login.salesforce.com/services/oauth2/authorize?client_id=${this.salesforceClientId}&redirect_uri=${this.salesforceCallbackUrl}&response_type=code`;
        // salesforceClientSecretUrl ='CFEADEB99321CAF49BFDC6B7207B01D6F289EE3023C470998DAEC834195F43D2';
        this.domainUrl = localStorage.getItem('domainUrlSalesforce') || '';
        this.openModal = false;
        this.disabledField = false;
        this.enabledBtn = false;
        this.loadResults = false;
        this.companyFields = {
            fields: {
                'Razão Social/Nome Fantasia': true,
                CNPJ: true,
                Setor: true,
                Endereço: true,
                Cidade: true,
                Estado: true,
                País: true,
                Telefones: true,
                Emails: true,
                'CNAE Primário': true,
                'CNAEs Secundários': true,
                'Faixa de Faturamento da Unidade CNPJ': true,
                'Faixa de Funcionários da Unidade CNPJ': true,
                'Quadro Societário': true,
                'Emails Principais': true,
                'Telefones Principais': true,
            },
            decisorsLevels: {
                Alto: true,
                Baixo: true,
                Médio: true,
                'Não Classificado': true,
            },
            decisorsDepartments: {
                Administrativo: false,
                Comercial: false,
                Financeiro: false,
                Gestão: false,
                Industrial: false,
                Jurídico: false,
                Marketing: false,
                Proprietários: false,
                'Recursos Humanos': false,
                Suprimento: false,
                TI: false,
                Operacional: false,
                Outros: false,
                Saúde: false,
            },
        };
        this.decisorsFields = [
            'Nome',
            'Cargo',
            'Departamento',
            'E-mail',
            'Link do linkedIn',
        ];
    }
    get linkVideo() {
        return getVideosCRM('salesforce');
    }
    hasSalesforceToken() {
        const token = localStorage.getItem('tokenSalesForce');
        return !!token && token != '';
    }
    domainUrlSaved() {
        const domainUrl = localStorage.getItem('domainUrlSalesforce');
        return !!domainUrl && domainUrl != '';
    }
    handleOpenModal() {
        return (this.openModal = true);
    }
    async saveDomainUrl() {
        this.loadResults = true;
        const token = localStorage.getItem('tokenSalesForce');
        try {
            const validDomainUrl = await Vue.axios.post(`${getApiExportBaseUrl()}/user/salesforce/validate_domain_url`, {
                domainUrl: this.domainUrl,
                token,
            });
            if (validDomainUrl && validDomainUrl.status == 201) {
                localStorage.setItem('domainUrlSalesforce', this.domainUrl);
                showNotify('URL de domínio salvo com sucesso!', 'success');
            }
        }
        catch (err) {
            showNotify('URL de domínio inválido!', 'error');
        }
        this.loadResults = false;
    }
    removeDomainUrl() {
        localStorage.removeItem('domainUrlSalesforce');
        this.domainUrl = '';
    }
    handleData(data) {
        return Object.keys(data);
    }
    handleBtnClick() {
        this.enabledBtn = true;
        window.open(this.urlSF, 'popup', 'height=525,width=600');
        return false;
    }
    get salesforceCallbackUrl() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? 'https://dev.speedio.com.br/crm/salesforce/callback'
            : hostname.match('test')
                ? 'https://test.speedio.com.br/crm/salesforce/callback'
                : hostname.match('app')
                    ? 'https://app.speedio.com.br/crm/salesforce/callback'
                    : hostname.match('staging')
                        ? 'https://test.speedio.com.br/crm/salesforce/callback'
                        : 'http://localhost:8080/crm/salesforce/callback';
    }
};
SalesforceConfigCard = __decorate([
    Component({
        name: 'SalesforceConfigCard',
        components: {
            Modal,
            Tooltip,
        },
    })
], SalesforceConfigCard);
export default SalesforceConfigCard;
