var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import UserHeaderContent from '../components/UserControlHeaderContent.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
import { showNotify } from '@/shared/utils/helpers';
let UserChangePasswordContainer = class UserChangePasswordContainer extends Vue {
    constructor() {
        super(...arguments);
        this.title = 'Alterar a senha';
        this.loading = false;
        this.newPassword = '';
        this.confirmPassword = '';
        this.actualPassword = '';
        this.dontPassword = true;
        this.notChanged = true;
        this.passwordSuccess = false;
    }
    cleanForm() {
        this.newPassword = '';
        this.confirmPassword = '';
        this.actualPassword = '';
    }
    async confirm() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.changePassword({
            /*eslint-disable */ old_password: this.actualPassword,
            password: this.newPassword,
            password_confirmation: this.confirmPassword /*eslint-disable */,
        });
        this.loading = false;
        if (
        // @ts-ignore TS2349: property inexistent
        this.state.statusResponse.type === 'change-password' &&
            // @ts-ignore TS2349: property inexistent
            this.state.statusResponse.code === 201) {
            this.notChanged = false;
            this.cleanForm();
            showNotify('Sua senha foi alterada com sucesso', 'success-password');
        }
        else if (
        // @ts-ignore TS2349: property inexistent
        this.state.statusResponse.message === 'Password wrong') {
            showNotify('Senha atual incorreta, tente novamente', 'error-password');
        }
        // @ts-ignore TS2349: property inexistent
        else if (this.state.statusResponse.code === 401) {
            // @ts-ignore TS2349: property inexistent
            this.$auth.logout();
        }
    }
    mounted() {
        this.$gtag.pageview({ page_path: this.$route.path });
        mixpanelTracking('painel do usuário: Abriu a aba Alterar senha');
        //@ts-ignore
        if (!this.state.have_password) {
            this.title = 'Criar senha';
            this.dontPassword = false;
        }
    }
    get saveButtonDisabled() {
        return (this.loading ||
            !this.isPasswordValid() ||
            (this.dontPassword && !this.actualPassword) ||
            this.newPassword !== this.confirmPassword);
    }
    isPasswordValid() {
        return /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(this.newPassword);
    }
};
UserChangePasswordContainer = __decorate([
    Component(connect(({ state, effects, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        components: {
            UserHeaderContent,
        },
    }))
], UserChangePasswordContainer);
export default UserChangePasswordContainer;
